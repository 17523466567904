.App {
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--accent-color);
  font-family: 'Times New Roman', Times, serif;
  
  font-size: larger;
}

.W {
  color: green;
}

.L {
  color: red;
}

.D {
  color: blue;
}

.form_h2h li {
  list-style: none;
}


header  {
  font-size: medium;
  font-family: 'Serif MT';
  border: none;
 
  
}

header .navbar__a {
  text-decoration: none;
  color: white;
}

.Side_menu {
  margin: 10px;
  padding: auto;
  border-radius: 16px;
  opacity: 1;
}

/* App.css */

.League_menu {
  list-style-type: none;
  cursor: pointer;
  padding: 10px;
  margin: 5px 0px;
  background-color: var(--primary-color);
  color: white;
  text-align: left;
  border-radius: 16px;
  width: 230px;
}

.all_predictions_menu {
  list-style-type: none;
  cursor: pointer;
  padding: 10px;
  margin: 5px 0px;
  background-color: var(--primary-color);
  color: white;
  text-align: right;
  border-radius: 16px;
  width: 300px;
  float: right;
}

.all_predictions_menu a {
  color: white;
  padding: 5px;
}

.all_predictions_menu li:hover {
  background-color: var(--secondary-color);
}


.League_menu:hover {
  background-color: var(--secondary-color);
}

.dropdown_menu {
  list-style-type: none;
  padding: 0px 5px 0px 0px;
  margin: 0px 1px 0px 0px;
  border: none;
  
  overflow-y: scroll;
    height: 454px;
}

.league li {
  font-weight: bold;
}

.dropdown_menu li {
  padding: 10px;
  color: white;
  margin: 5px 3px;
  background-color: rgb(6, 95, 6);
  border-radius: 10px;
  font-size: large;
  border: 1px solid var(--accent-color);
}

.dropdown_menu li:hover {
  background-color: var(--primary-color);
  color: #292828;
}
.dropdown_menu li.active {
    background-color: var(--highlight-color);
    color: white;
}

.dropdown_menu li:last-child {
  border-bottom: none;
}


.Side_bar {
  background-color: snow;
  border-radius: 16px;
  max-width: 300px;
  
  margin: 30px auto;
  padding: 10px;
  list-style-type: none;
}

.side_picks a {
  text-decoration: none;
  list-style: none;
  color: black;
}

.side_picks {
  color: black;
  text-align: left;
  padding: 10px;
}

.Side_bar li {
  list-style-type: none; 
  font-size: small;
  padding: 10px auto;
  margin-bottom: 5px;
  font-size: large;
}

.Side_bar .bold {
  margin: 10px;
  font-weight: bold;
  color: aqua;
}

.Side_bar button {
    background-color: var(--highlight-color);
    border: 10px;
    padding: 0px 10px;
    cursor: pointer;
    color: rgb(253, 253, 253);
    font-size: 20px;
    font-style: oblique;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 1px 2px 10px rgba(248, 248, 248, 0.4);
                        
}

.Side_bar  button h4 {
  padding: 1px;
}

.top_picks {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  text-align: left;
  background-color: #292828;
  color: aliceblue;
}


@media (max-width: 570px) {
  .League_menu img {
      width: 10px;
      height: 10px;
  }

  header .comment_button {
    width: 35px;
    height: 35px;
  }

  .mobile-menu-button {
    width: 45px;
    height: 30px;
  }

  .socials img {
    width: 24px;
    height: 24px;
}
}


@media (max-width: 960px) {
  .League_menu img {
      width: 20px;
      height: 10px;
  }
  
header .comment_button {
  width: 35px;
  height: 35px;
}

header  {
  position: sticky;
  top: 0px;
  
}

.mobile-menu-button {
  width: 45px;
  height: 30px;
}

.socials img {
  width: 24px;
  height: 24px;
}
}

.top_menu {
  width: 100%;
}


/* TopMenu Styling */
.top_header {
  display: flex;
  flex-direction: row; /* Reverse the direction to display "current-week" first */
  padding: 10px;
  min-width: 360px;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping of content */
  position: relative; /* For shadow positioning */
  scroll-behavior: smooth; /* Smooth scroll on load */
}

.top_header::-webkit-scrollbar {
  height: 2px; /* Set a small height for the scrollbar */
  background: rgba(0, 0, 0, 0.1); /* Light background for the scrollbar track */
}

.top_header::-webkit-scrollbar-thumb {
  background-color: var(--primary-color); /* Customize the scrollbar thumb */
  border-radius: 10px;
}



.navbar__menu {
  text-decoration: none;
  display: inline-block;
}

.history_nav {
  color: white;
  margin: auto;
}

.top_header button {
  background-color: white;
  color: var(--primary-color);
  margin: auto 10px;
  border: none;
  border-radius: 7px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.2s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  white-space: nowrap; /* Prevent text wrapping */
}

@media (max-width: 410px) {
  .top_header button {
    padding: 7px 10px;
  }

}

.top_header button:hover {
  background-color: var(--secondary-color);
}

.top_header button:active,
.top_header button.active {
  background-color: rgb(176, 223, 176);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translateY(2px);
}

.side_socials img {
  margin: 10px;
  width: 34px;
  height: 34px;
}

.content-menu li {
  font-size: large;
}