*{
  height: fit-content;
}
  
  *::-webkit-scrollbar {
    width: 0.5vw;
    border-radius: 5px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #072635;
    border-radius: 5px;
    height: 25px;
  }

  .comment::-webkit-scrollbar {
    display: none;
  }
  

  *::-webkit-scrollbar-track {
    background-color: #E3E4E6;
    border-radius: 5px;
    
   
  }

  *::-webkit-scrollbar-button {
    display: none; 
    scrollbar-arrow-color: transparent;
}

body {
  margin: 0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

li {
  border: none;
}


:root {
  --primary-color: #69b86c;
  --secondary-color: #95dd98;
  --accent-color: #7c97a5;
  --highlight-color: #f08869;
  --background-color: #F6F7F8 ;
  --content-color: rgba(149, 221, 152, 0.5);
  box-shadow: inset 0 0 0 2000px rgba(231, 238, 235, 0.2);
 
}


body {
  background-image: url(./media/football-488714_1920.jpg);
  color: var(--accent-color);
  background-repeat: no-repeat;
  background-size: cover;
}

header {
  background-color: var(--primary-color);
  color: white;
    padding: 10px;
    margin: 10px 5px;
    border-radius: 70px;
    border: none;
}

.menu {
  background-color: var(--secondary-color);
}

.menu li:hover {
  background-color: var(--primary-color);
  color: white;
}

.alert {
  color: var(--highlight-color);
}

.button-primary {
  background-color: var(--primary-color);
  color: white;
}

.button-secondary {
  background-color: var(--secondary-color);
  color: white;
}