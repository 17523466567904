.Home {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
}

.close-btn {
    
    background: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    margin: auto;
    border-radius: 5px;
}

.content_header {
    display: flex;
    flex-direction: row;
}

@media (min-width: 960px) {
    .content, .content_body {
        min-width: 300px;
        max-width: 550px;
        margin: auto;
       
    }
    .Side_bar, .Side_menu {
        margin: 20px auto;
        
    }

  

    .Home {
        padding: 10px;
    }
}

@media (max-width: 370px) {
    .content, .content_body {
        min-width: 250px;
        max-width: 550px;
        margin: auto;
       
    }

     .Home {
        padding: 3px;
    }
}

.all_predictions_menu {
        position: fixed;
        margin: 0px;
        z-index: 9;
       right: 2%;
       top: 20%;
      float: right;
      cursor: pointer;
}
@media (max-width: 960px) {
    .Side_menu {
        position: fixed;
        top: 15%;
        margin: 0px;
        z-index: 9;
    }

    .all_predictions_menu {
        position: fixed;
        margin: 0px;
        z-index: 9;
       top: 15%;
      float: right;
      cursor: pointer;
    }

    .Home {
        display: block;
        margin: auto;
        margin-top: 10px;
    }

    .Side_bar {
        max-width: 300px;
        margin: 20px auto;
    }

    .content, .content_body, .content-menu {
        max-width: 600px;
       
    }

    h1 {
        font-size: 1.5em;
    }

    .content_default {
        margin: auto;

    }

}


.content {
    display: flex;
    flex-direction: column;
    color: black;
    height: fit-content;
    margin: auto;
}

.content-menu {
    display: flex;
    
    list-style-type: none;
    justify-content: space-between;
    padding: 0px;
    margin: 5px;
}

.content-menu li {
    cursor: pointer;
    padding: 10px;
    background-color: var(--highlight-color);
    margin: 5px;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s;
    box-shadow: 1px 2px 10px rgba(248, 248, 248, 0.4);
}

@media (max-width: 470px) {
    .content-menu li {
        margin: 3px;
        padding: 5px 10px;
    }
}

.comment_button {
    width: 35px;
    height: 35px;
     margin: auto 10px;
      
      float: right;
      cursor: pointer;
}

.prediction_button {
    width: 100px;
    height: 35px;
     margin: auto 10px;
      
      float: right;
      cursor: pointer;
}


.content-menu li:hover,
.content-menu li.active {
    background-color: var(--highlight-color);
    color: #fff;
    box-shadow: inset 1px 2px 10px rgba(255, 255, 255, 0.4);
}

header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.content_default {
    background-color: #f0f0f0;
    height: fit-content;
    box-shadow: -5px -10px 10px rgba(36, 35, 35, 0.4);
    border-radius: 10px;
    font-family:'Times New Roman', Times, serif;
    text-align: left;
    padding: 20px;
    margin-top: 5%;
    max-width: 600px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    color: #044b0a;
   
}

.content_default h2,.content_default h3 {
    color: black;
    font-style: italic;
    font-family: 'Times New Roman', Times, serif;
    
}

.content_default h5, .content_default h6, .content_default p {
    padding: 3px;
    margin: 3px 3px;
    
}


.content_default .high_accuracy {
    color: #03ffaf;
}

.content_default .medium_accuracy {
    color: #03ffaf;
}

.content_default ul, dl {
    text-align: left;
    list-style: none;
}

.content_default li {
    list-style:disc;
    font-family:'Times New Roman', Times, serif;
    font-style: italic;
    font-size: 20px;
    color: #fff;
}

.content_default .high_accuracy {
    color: #2ECC71;
}
.content_default .medium_accuracy {
    color: #E67E22;
}


.highlight_p {
    color: #d60832;
    font-size: Sans-serif;
    
}

.search-results {
    list-style: none;
    display: flex;
    padding: 5px;
    flex-wrap: wrap;
    position: absolute;
    background-color: rgba(46, 204, 113,0.6);
    z-index: 100;
    max-width: 600px;
    border-radius: 10px;
    margin: auto;
}

.all_fixtures {
    list-style: none;
    display: flex;
    padding: 5px;
    flex-wrap: wrap;
    background-color: rgba(46, 204, 113,0.6);
    z-index: 100;
    max-width: 600px;
    border-radius: 10px;
    margin: auto;
}

.all_fixtures li {
    cursor: pointer;
    border: 1px solid var(--accent-color);
    border-radius: 10px;
    font-size: medium;
    list-style: none;
    padding: 5px;
    color: black;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.6);
}

.all_fixtures li:hover {
    background-color: rgba(242, 202, 212, 0.3);
}

.search-results li {
    cursor: pointer;
    border: 1px solid var(--accent-color);
    border-radius: 10px;
    font-size: medium;
    list-style: none;
    padding: 5px;
    color: black;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.6);
}

.search-results li:hover {
    background-color: rgba(242, 202, 212, 0.3);
}


.day_title {
    font-size: 25px;
    font-weight: bold;
    color: #000;
    background-color: #f0f0f0;
    text-align: center;
    margin: 7px;
}

