header {
    background-color: var(--primary-color);
    color: white;
    
    border-radius: 70px;
    border: none;
 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 960px) {
  header {
    align-items:center;
  }
}


.about_body {
    background-color: rgba(245, 245, 220, 0.8);
    color: black;
    margin: 5% 7%;
    padding: 3% 7%;
    text-align: center;
  }

  .about_body img {
    width: 150px;
    height: 150px;
    margin: auto;
    
  }

  .socials {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    margin: auto;
    height: 24px;
    justify-content: space-around;


}

.socials img {
    width: 24px;
    height: 24px;
}

  .about_body .pitch {
    font: bold;
    font-size: larger;
}

.red_lettered {
    padding: 10px;
    background-color: red;
    color: white;
}

.privacy_policy {
  text-align: left;
  margin: 5% 13%;
  padding: 3% 7%;
}

.main_privacy_policy {
  background-color: hsla(60, 27%, 96%, 0.837);
  color: black;
  font-size: large;
  margin: 3% 10%;
  padding: 3% 5%;
  text-align: center;
}

.main_install_app {
  background-color: hsla(60, 27%, 96%, 0.837);
  color: black;
  max-width: 500px;
  font-size: large;
  margin: 3% auto;
  padding: 3% 5%;
  text-align: left;
}

.main_install_app img {
  max-width: 350px;
  min-height: 100px;
  max-height: 300px;
}

.main_install_app ol {
  margin: 4px auto;
}

.main_install_app li {
  line-height: 55px;
}

.main_install_app h3 {
  margin-top: 15px;
}


@media (max-width: 600px) {
  .main_install_app {
    min-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main_install_app img {
    max-width: 250px;
    min-height: 50px;
    max-height: 200px;
  }
}



.main_faq {
  background-color: hsla(60, 27%, 96%, 0.837);
  color: black;
  margin: 3% 5%;
  padding: 3% 10%;
  text-align: center;
}

.socials img {
    width: 24px;
    height: 24px;
}

@media (max-width: 600px) {
  .main_faq, .about_body, .privacy_policy, .main_privacy_policy, .main_install_app {
    margin: 1% 1%;
    padding: 1% 3%;
    border-radius: 10px;
  }
}

.faq_list {
  text-align: left;
  margin: 5% 13%;
  padding: 3% 7%;
}

.faq_list li {
  font-weight: bold;
}

.answer {
  
  gap: 5px;
  font-style: italic;
}

.bold_list {
  font-weight: bold;
}

.subheading {
  font-weight: bold;
}


