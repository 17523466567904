.footer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: fit-content;
    gap: 24px;
    align-items: center;
    padding-top: 20px;
    margin: 20px auto;
    margin-bottom: 0%;
    
    width: 100%;
    color: white;
    opacity: 0.9;
    
    background-color: var(--secondary-color);
}



.footer_right li {
    line-height: 1.7;
}

.comment::-webkit-scrollbar {
    display: none;
  }
  

 .footer a {
    color: #1d1c1c;
    text-decoration: none;
    cursor: pointer;
}


.footer_body {
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: space-between;
    
}

.footer_left {
    max-width: 550px;
    
    background-color: #d8f1c8;
    color: black;
    height: 650px;
    overflow-y: scroll;
    border-radius: 10px;
    
}

.footer_right, .footer_left {
    display: flex;
    flex-direction: column;
    list-style: none;
    text-align: left;
    padding: 20px;
}


@media (max-width: 760px) {
    .footer_body {
        display: flex;
        flex-direction: column;
    }
    .footer_left { 
        max-width: 350px;
    }
}

@media (min-width: 760px) {
   
    .footer_left { 
        min-width: 350px;
    }
}



 .footer   .divider {
        
    height: 1px;
    width: 100px;
    position: relative;
    background: #f15f5f;
    opacity: 0.4;
    margin: auto;



    }

    .footer .footer_logo {
        display: flex;
        flex-direction: row;
    }


    .copyrights {
        font-size: smaller;
    }

    .socials {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        margin: auto;
        height: 24px;
        justify-content: space-between;


    }

    .socials img {
        width: 24px;
        height: 24px;
    }

    
   
